<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Area ICREA</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Select area</label>
              <v-select
                v-model="user.areas"
                label="name"
                disabled
                :clearable="false"
                :get-option-key="option => option.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      selectedArea: {},
    }
  },
  computed: {
    ...mapGetters({
      // areas: 'areas/areas',
    }),
  },
  mounted() {
    if (Array.isArray(this.user.areas)) {
      [this.user.areas] = this.user.areas
    }
  },
}
</script>

<style>

</style>
