<template>
  <div
    v-if="user.new_icrea"
    class="card"
  >
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">New ICREA text</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="mb-1">
              <label
                for=""
                class="form-label disp-bl"
              >Content</label>
              <div id="full-wrapper">
                <div id="full-container">
                  <quill-editor
                    v-model="user.new_icrea_text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
}
</script>
