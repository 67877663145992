<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Card</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-1 form-group">
              <label
                for=""
                class="form-label"
              >Id card type</label>
              <v-select
                v-if="loggedUser.roles.includes('super-admin')"
                v-model="user.card"
                label="name"
                :options="cards"
                :get-option-key="option => option.id"
              />
              <div v-else>
                {{ user.card ? user.card.name : '-' }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1">
              <label
                class="form-label"
                for=""
              >Id card number</label>
              <input
                v-if="loggedUser.roles.includes('super-admin')"
                v-model="user.id_card_number"
                type="text"
                class="form-control"
              >
              <div v-else>
                {{ user.id_card_number }}
              </div>
              <div
                id="emailHelp"
                class="form-text"
              >
                (do not include -, /, …)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  computed: {
    ...mapGetters({
      cards: 'cardTypes/items',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>

<style>

</style>
