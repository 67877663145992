<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Identification codes</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <!-- <div class="col-md-3">
            <h4>Id ICrea</h4>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Code</label>
              <input
                v-model="user.id_icrea"
                type="text"
                class="form-control"
              >
            </div>
          </div> -->
          <div class="col-md-4">
            <h4>Resercher ID (Web of Science)</h4>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Code</label>
              <input
                v-model="user.researcher_id"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-4">
            <h4>Author ID (SCOPUS)</h4>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Code</label>
              <input
                v-model="user.author_id"
                type="text"
                class="form-control"
                value="7003762355"
              >
            </div>
          </div>
          <div class="col-md-4">
            <h4>ORCID</h4>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Code</label>
              <input
                v-model="user.orcid"
                type="text"
                class="form-control"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: { type: Object, required: true, default: () => {} },
  },

}
</script>

<style>

</style>
