<template>
  <div class="card card--table">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">List others ERC panels</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ERC panel</th>
              <th class="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="array_ercs, index in user.additional_ercs"
              :key="index"
            >
              <td>
                <p
                  class="mb-0"
                >
                  <strong>{{ array_ercs.level1.custom_name }}</strong>
                </p>
                <p
                  v-if="array_ercs.level2"
                  class="mb-0"
                >
                  {{ array_ercs.level2.custom_name }}</p>
                <p
                  v-if="array_ercs.level3"
                  class="mb-0"
                >
                  {{ array_ercs.level3.custom_name }}</p>
              </td>
              <td class="text-end">
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete  ERC panel"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-danger"
                  data-bs-toggle="modal"
                  @click="deleteAdditionalErcs(array_ercs.level1.pivot ? array_ercs.level1.pivot.group_identification : index, array_ercs.level1.pivot ? 'fromDB' : 'new')"
                ><i data-feather="trash-2" /></a></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-body">
        <a
          class="btn btn-sm btn-dark dis-ib"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseAddERC"
          @click="openEdit = !openEdit"
        ><i data-feather="plus" /> Add ERC panel</a>

        <b-collapse
          :visible="openEdit"
          class="collapse mt-3"
        >
          <div class="card shadow-none bg-transparent border-secondary">
            <div class="card-body">
              <h3>Add new ERC</h3>
              <div class="row">
                <div class="col-12">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Level 1</label>
                    <v-select
                      v-model="newLevel1"
                      label="custom_name"
                      :options="levels1"
                      :get-option-key="option => option.id"
                      @input="getChildrens($event, 'level1')"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Level 2</label>
                    <v-select
                      v-model="newLevel2"
                      label="custom_name"
                      :options="levels2"
                      :get-option-key="option => option.id"
                      @input="getChildrens($event, 'level2')"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >Level 3</label>
                    <v-select
                      v-model="newLevel3"
                      label="custom_name"
                      :options="levels3"
                      :get-option-key="option => option.id"
                      @input="getChildrens($event, 'level3')"
                    />
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <a
                  class="btn btn-sm btn-outline-success me-1"
                  @click="addAdditional"
                >Add ERC panel</a>
                <a
                  class="btn btn-sm btn-flat-danger"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseAddFile2"
                  @click="openEdit = false"
                >Cancel</a>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      openEdit: false,
      levels2: [],
      levels3: [],
      newLevel1: null,
      newLevel2: null,
      newLevel3: null,
      test: [],
      randomString: '',
    }
  },
  computed: {
    ...mapGetters({
      levels1: 'erc/parentLevels',
      // levels1: 'erc/items',
    }),
  },
  async mounted() {
    // await this.$store.dispatch('erc/filter', { level1: 'yes' })
  },
  methods: {
    getChildrens(event, parent) {
      switch (parent) {
        case 'level1':
          Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${event.id}/childrens`).then(resp => {
            this.levels2 = resp.data
          })
          // this.levels2 = event ? event.childrens : []

          break
        case 'level2':
          Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${event.id}/childrens`).then(resp => {
            this.levels3 = resp.data
          })
          // this.levels3 = event ? event.childrens : []
          break
        default:
      }
    },
    addAdditional() {
      if (this.newLevel1) {
        this.randomString = this.generateRandomString(5)
        this.user.additional_ercs.push({ level1: this.newLevel1 ? { ...this.newLevel1, num_level: 1, aux_group_identification: this.randomString } : null, level2: this.newLevel2 ? { ...this.newLevel2, num_level: 2, aux_group_identification: this.randomString } : null, level3: this.newLevel3 ? { ...this.newLevel3, num_level: 3, aux_group_identification: this.randomString } : null })
        this.newLevel1 = null
        this.newLevel2 = null
        this.newLevel3 = null
      }
      this.openEdit = false
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async deleteAdditionalErcs(identification, from) {
      try {
        if (identification) {
          switch (from) {
            case 'fromDB':
              await this.$store.dispatch('users/deleteAdditionalEtcs', { id: this.user.id, identification })
              break
            case 'new':
              this.user.additional_ercs.splice(identification, 1)
              break
            default:
          }
          Vue.swal('Erc deleted successfully!', '', 'success')
        }
      } catch (e) {
        Vue.swal('Error on delete', '', 'error')
      }
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    generateRandomString(length) {
      const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()'
      const charLength = chars.length
      let result = ''
      for (let i = 0; i < length; i += 1) {
        result += chars.charAt(Math.floor(Math.random() * charLength))
      }
      return result
    },
  },

}
</script>

<style>

</style>
